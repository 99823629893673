export const SurveyTypes = {
  1: 'Controle',
  2: 'Inspectie',
  3: 'Keuring',
  4: 'Oplevering',
  5: 'Bewijslast',
  6: 'Voorschouw',
  7: 'Keuringsdocument'
}

export const SurveyStates = {
  Scheduled: 1,
  Concept: 2,
  Overdue: 3,
  Completed: 4
}

export const SurveyStateLabels = {
  1: 'Ingepland',
  2: 'Concept',
  3: 'Verlopen',
  4: 'Voltooid'
}

export const SurveyStateLabelColors = {
  1: '#2ea3f2',
  2: '#ffc107',
  3: '#ff0000',
  4: 'green'
}

export const OpenSurveyStates = [
  SurveyStates.Scheduled,
  SurveyStates.Concept
]

export const ClosedSurveyStates = [
  SurveyStates.Overdue,
  SurveyStates.Completed
]
